import React, { Component } from "react"
import { GlobalSection, GlobalContainer, Button } from "../components/GlobalStyle"
import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import headerImage from "../images/slider/slide-2.jpg"
import styled from "styled-components"
import buildingsIcon from "../images/icons/buildings.svg"
import presentationIcon from "../images/icons/presentation.svg"
import tableIcon from "../images/icons/table.svg"
import axios from "axios"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';

const StyledFormWrap = styled.div`
  padding: 20px;
  max-width: 1040px;
  margin: 0 auto;
  @media (max-width: 500px) {
    padding: 0;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  input,textarea {
    width: 100%; 
    display: block;
    color: rgba(0, 0, 0, 0.8);
    padding: 17px 26px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-weight: 400;
    transition: all 0.3s ease;
    font: 14px 'Mukta',sans-serif;
  }
`
const StyledFormRow = styled.div`
  display: flex;
  text-align: center;
  div {
    flex: 1;
    margin: 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    div {
      margin: 5px;
    }
`
const StyledFormTiles = styled.div`
  display: flex;
  margin: 0 !important;
  flex-wrap: wrap;
  div {
    flex: ${props => props.grid ? "none" : "1"};
    width: ${props => props.grid ? "calc(100% / 4 - 20px)" : "auto"};
    @media (max-width: 600px) {
      width: ${props => props.grid ? "calc(100% / 4 - 10px)" : "auto"};
    }
    @media (max-width: 500px) {
      width: ${props => props.grid ? "calc(100% / 4 - 5px)" : "auto"};
    }
  }
  
`
const StyledFormTile = styled.div`
  flex: 1;
  position: relative;
  @media (max-width: 500px) {
    margin: 2px !important;
  }
  div {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    width: 100%;
    @media (max-width: 750px) {
      font-size: 14px;
      padding: 20px 5px;
      margin: 2px;
    }
    @media (max-width: 420px) {
      font-size: 12px;
      padding: 20px 5px;
      margin: 2px;
    }
  }
  span {
    font-weight: 300;
  }
  img {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
    @media (max-width: 750px) {
      height: 48px;
      width: 48px;
    }
  }
`
const StyledFormTileTooltip = styled.span`
  background-color: var(--main);
  display: inline-block;
  height: 20px;
  width: 20px;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 20px;
  i {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    position: relative;
    top: 1px;
    cursor: pointer;
  }
  div {
    display: none;
    position: absolute;
    width: 200px;
    left: -100px;
    top: -110px;
    z-index: 10;
    background-color: #333;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    &::after {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      background-color: #333;
      transform: rotate(45deg) translateX(-50%);
      position: absolute;
      border-radius: 2px;
      left: calc(50% - 2px);
      bottom: -16px;
    }
  }
  &:hover {
    div {
      display: inherit;
    }
  }
`
const StyledFormRadio = styled.input `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  &:checked + div {
    color: var(--main);
    border-color: var(--main);
  }
`
const StyledFormLabel = styled.div`
  text-align: left;
  margin: 20px 0;
  padding-left: 15px;
`
const StyledFormRadioText = styled.div`
  border: 1px solid rgba(0,0,0,0.15);
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  color: rgba(0,0,0,0.6);
  font-weight: bold;
  margin: 0 !important;
`
const StyledFormCheckbox = styled.div`
  margin: 20px 0;
  * {
    font-weight: 400;
    font-size: 16px;
  }
  a {
    text-decoration: underline;
    margin-left: 5px;
    color: #000;
    opacity: .6;
  }
  label {
    span {
      display: inline-block;
      height: 32px;
      width: 32px;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,0.3);
      border-radius: 2px;
      margin-right: 10px;
      position: relative;
      z-index: 2;
      top: 10px;
      transition: border-color .3s;
      cursor: pointer;
      &:hover {
        border-color: var(--main);
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 6px;
        width: 12px;
        border-left: 3px solid;
        border-bottom: 3px solid;
        border-color: rgba(0,0,0,0.2);
        top: calc(50% - 1px);
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: border-color .3s;
      }
    }
    input {
      display: inline-block;
      height: 20px;
      width: 20px;
      z-index:2;
      opacity: 0;
      position: absolute;
    }
    input:checked ~ span {
      border-color: var(--main);
    }
    input:checked ~ span:after {
      border-color: var(--main);
    }
  }
`
// const StyledFormPromo = styled.div`
//   margin-bottom: 20px;
//   padding: 10px;
//   background-color: rgba(0, 166, 90, 0.1);
//   border: 1px solid rgba(0, 166, 90, 0.3);
// `

const StyledFormButton = styled.button`
  border-radius: 32px;
  font-weight: 700;
  font-size: 18px;
  padding: 18px 56px;
  min-width: 245px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  border: 0;
  background-clip: border-box !important;
  transition: background 0.3s, color 0.3s !important;
  color: var(--black);
  transform: scale(1);
  background-color: var(--main);
  &:hover {
    background-color: #008032;
    color: #fff;
  }
`
const StyledFormRadioPrice = styled.span`
  font-size: 1.4em !important;
  color: var(--main);
  font-weight: 700 !important;
`
const StyledDatePickerWrap = styled.span`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  @media (max-width: 600px) {
    padding: 10px 5px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    margin: 0;
  }
  .react-datepicker-popper {
    width: 200px;
  }
  input {
    width: 100%;
  }
`
const StyledMessageBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: .3s;
  div {
    background-color: #fff;
    border-radius: 2px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 0 20px 20px rgba(0,0,0,0.05);
    }
    p {
      margin: 20px 0;
    }
    span {
      font-weight: bold;
      font-size: 2em;
      color: var(--main);
    }
  }
`
const StyledOfferNet = styled.span`
  display: inline-block;
  font-size: .8em;
  font-weight: bold !important;
  margin-left: 2px;
`
const salaDuza = [
  {
    id: 3,
    price: "349",
    hours: "reservations.3hours",
    plan: "3-godziny",
  },
  {
    id: 4,
    price: "799",
    hours: "reservations.1day",
    plan: "1-dzien",
  },
  {
    id: 5,
    price: "1699",
    hours: "reservations.2days",
    plan: "2-dni",
  },
]
const salaAkwarium = [
  {
    id: 1,
    price: "69",
    hours: "1 godzina",
    plan: "1-godzina",
  },
  {
    id: 2,
    price: "189",
    hours: "3 godziny",
    plan: "3-godziny",
  },
  {
    id: 3,
    price: "499",
    hours: "1 dzień",
    plan: "1-dzien",
  },
  {
    id: 4,
    price: "1349",
    hours: "3 dni",
    plan: "3-dni",
  },
  {
    id: 5,
    price: "650",
    hours: "10 godzin (abonament)",
    plan: "10-godzin",
  },
  {
    id: 6,
    price: "1200",
    hours: "20 godzin (abonament)",
    plan: "20-godzin",
  },
  {
    id: 7,
    price: "1650",
    hours: "30 godzin (abonament)",
    plan: "30-godzin",
  }
]
const salaDolna = [
  {
    id: 1,
    price: "59",
    hours: "1 godzina",
    plan: "1-godzina",
  },
  {
    id: 2,
    price: "169",
    hours: "3 godziny",
    plan: "3-godziny",
  },
  {
    id: 3,
    price: "399",
    hours: "1 dzień",
    plan: "1-dzien",
  },
  {
    id: 4,
    price: "1089",
    hours: "3 dni",
    plan: "3-dni",
  },
  {
    id: 5,
    price: "550",
    hours: "10 godzin (abonament)",
    plan: "10-godzin",
  },
  {
    id: 6,
    price: "1000",
    hours: "20 godzin (abonament)",
    plan: "20-godzin",
  },
  {
    id: 7,
    price: "1350",
    hours: "30 godzin (abonament)",
    plan: "30-godzin",
  }
]


class ReservationPage extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      type: "coworking",
      plan: "",
      planType: "abonament",
      name: "",
      surname: "",
      phone: "",
      email: "",
      rentTime: "",
      date: new Date(),
      salaTyp: "",
      companyName: "",
      companyNIP: "",
      companyREGON: "",
      companyAddress: "",
      message: "",
      invoice: "tak",
      messageBox: false,
    };
    this.state = this.initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange = changeEvent => {
    this.setState({
      [changeEvent.target.name]: changeEvent.target.value
    });
  };

  handleTypeChange = changeEvent => {
    this.setState(this.initialState);
    this.setState({
      [changeEvent.target.name]: changeEvent.target.value
    });
  };

  handleDateChange = date => {
    this.setState({
      date: date
    });
  };

  async handleSubmit(event) {
    event.preventDefault();
    const { type, plan, planType, name, surname, phone, email, rentTime, date, salaTyp, companyName, companyNIP, companyREGON, companyAddress, message, invoice } = this.state;
    await axios.post(
      'https://aqnsovzeld.execute-api.eu-west-1.amazonaws.com/beta/registration',
      {
        type,
        plan,
        planType,
        name,
        surname,
        phone,
        email,
        rentTime,
        date,
        salaTyp,
        companyName,
        companyNIP,
        companyREGON,
        companyAddress,
        message,
        invoice
      })
      .then(
        this.setState({
          messageBox: true,
        })
      )
      .catch(function(error) {
        console.log(error);
      })
  }
  render() {
    return (
      <>
        <SEO
          title="Rezerwacja"
          description="Umów się już dzisiaj na wizytę w naszym coworkingu. Wypełnij prosty formularz rezerwacji a my skontaktujemy się z Tobą w celu omówienia szczegółów"
        />
        <PageHeader image={headerImage}>
          <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('header.reservationTitle')}</h1>
          <p data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">{this.props.t('header.reservationText1')}</p>
          <p data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">{this.props.t('header.reservationText2')}</p>
        </PageHeader>
        <GlobalSection  bg={"var(--gray)"}>
          <GlobalContainer>
            <StyledFormWrap>
              <form onSubmit={this.handleSubmit}>

                {
                  // Reservation type
                }
                <StyledFormRow>
                  <StyledFormTiles>
                    <StyledFormTile>
                      <StyledFormRadio
                        type="radio"
                        name="type"
                        value="coworking"
                        checked={this.state.type === "coworking"}
                        onChange={this.handleTypeChange}/>
                      <StyledFormRadioText>
                        <img src={tableIcon} alt="" />
												{this.props.t('reservations.desk')}
                      </StyledFormRadioText>
                    </StyledFormTile>
                    <StyledFormTile>
                      <StyledFormRadio
                        type="radio"
                        name="type"
                        value="sala-konferencyjna"
                        checked={this.state.type === "sala-konferencyjna"}
                        onChange={this.handleTypeChange}/>
                      <StyledFormRadioText>
                        <img src={presentationIcon} alt="" />
												{this.props.t('reservations.conference')}
                      </StyledFormRadioText>
                    </StyledFormTile>
                    <StyledFormTile>
                      <StyledFormRadio
                        type="radio"
                        name="type"
                        value="wirtualne"
                        checked={this.state.type === "wirtualne"}
                        onChange={this.handleTypeChange} />
                      <StyledFormRadioText>
                        <img src={buildingsIcon} alt="" />
												{this.props.t('reservations.virtualOffice')}
                      </StyledFormRadioText>
                    </StyledFormTile>
                  </StyledFormTiles>
                </StyledFormRow>

                {
                  // Contact data
                }
                <StyledFormLabel>{this.props.t('reservations.contactDetails')}</StyledFormLabel>
                <StyledFormRow>
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange.bind(this)}
                      id="name"
                      placeholder={this.props.t('reservations.contactName')}
                      required />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="surname"
                      value={this.state.surname}
                      onChange={this.handleChange.bind(this)}
                      id="surname"
                      placeholder={this.props.t('reservations.contactLastname')}
                      required />
                  </div>
                </StyledFormRow>
                <StyledFormRow>
                  <div>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleChange.bind(this)}
                      placeholder={this.props.t('reservations.contactPhone')} />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)}
                      placeholder={this.props.t('reservations.email')}
                      required />
                  </div>
                </StyledFormRow>

                {
                  // Plan
                }

                {
                  this.state.type === "coworking"
                &&
                  <>


                    <StyledFormLabel>{this.props.t('reservations.chooseType')}</StyledFormLabel>

                    <StyledFormRow>
                      <StyledFormTiles>
                        {/*<StyledFormTile>*/}
                        {/*  <StyledFormRadio*/}
                        {/*    type="radio"*/}
                        {/*    name="planType"*/}
                        {/*    value="hotdesk"*/}
                        {/*    checked={this.state.planType === "hotdesk"}*/}
                        {/*    onChange={this.handleChange}/>*/}
                        {/*  <StyledFormRadioText>*/}
												{/*	{this.props.t('reservations.typeHotdesk')}*/}
                        {/*    <span>( {this.props.t('reservations.shortTerm')} )</span>*/}
                        {/*  </StyledFormRadioText>*/}
                        {/*</StyledFormTile>*/}
                        <StyledFormTile>
                          <StyledFormRadio
                            type="radio"
                            name="planType"
                            value="abonament"
                            checked={this.state.planType === "abonament"}
                            onChange={this.handleChange} />
                          <StyledFormRadioText>
													{this.props.t('reservations.sub')}
                            <span>( 	{this.props.t('reservations.longTerm')} )</span>
                          </StyledFormRadioText>
                        </StyledFormTile>
                      </StyledFormTiles>
                    </StyledFormRow>


                    { this.state.planType == "hotdesk" &&
                    <>
                      <StyledFormLabel>{this.props.t('reservations.choosePackage')}</StyledFormLabel>
                      <StyledFormRow>
                        <StyledFormTiles>
                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-hotdesk-1dzien-50zl"
                              checked={this.state.plan === "cowork-hotdesk-1dzien-50zl"}
                              onChange={this.handleChange}/>
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>50zł</StyledFormRadioPrice>
                              1 {this.props.t('common.day')}
                            </StyledFormRadioText>
                          </StyledFormTile>
                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-hotdesk-3dni-120zl"
                              checked={this.state.plan === "cowork-hotdesk-3dni-120zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>120zł</StyledFormRadioPrice>
                              3 {this.props.t('offerCoworking.days')}
                            </StyledFormRadioText>
                          </StyledFormTile>
                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-hotdesk-3dni-dedykowane-149zl"
                              checked={this.state.plan === "cowork-hotdesk-3dni-dedykowane-149zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>145zł</StyledFormRadioPrice>
                              3 {this.props.t('offerCoworking.days')}
                              <StyledFormTileTooltip>
                                <i>!</i>
                                <div>{this.props.t('reservations.tooltip')}</div>
                              </StyledFormTileTooltip>
                            </StyledFormRadioText>
                          </StyledFormTile>
                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-hotdesk-15dni-349zl"
                              checked={this.state.plan === "cowork-hotdesk-15dni-349zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>340zł</StyledFormRadioPrice>
                              15 {this.props.t('offerCoworking.days')}
                            </StyledFormRadioText>
                          </StyledFormTile>

                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-hotdesk-15dni-dedykowane-449zl"
                              checked={this.state.plan === "cowork-hotdesk-15dni-dedykowane-449zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>440zł</StyledFormRadioPrice>
                              15 {this.props.t('offerCoworking.days')}
                              <StyledFormTileTooltip>
                                <i>!</i>
                                <div>{this.props.t('reservations.tooltip')}</div>
                              </StyledFormTileTooltip>
                            </StyledFormRadioText>
                          </StyledFormTile>
                      {/*    <StyledFormTile>*/}
                      {/*      <StyledFormRadio*/}
                      {/*        type="radio"*/}
                      {/*        name="plan"*/}
                      {/*        value="cowork-miesiac-750"*/}
                      {/*        checked={this.state.plan === "cowork-miesiac-750"}*/}
                      {/*        onChange={this.handleChange} />*/}
                      {/*      <StyledFormRadioText>*/}
                      {/*        <StyledFormRadioPrice>1999zł</StyledFormRadioPrice>*/}
                      {/*        6 Miesięcy*/}
                      {/*      </StyledFormRadioText>*/}
                      {/*    </StyledFormTile>*/}
                        </StyledFormTiles>
                      </StyledFormRow>
                    </>
                    }

                    { this.state.planType == "abonament" &&
                    <>
                      <StyledFormLabel>{this.props.t('reservations.choosePackage')}</StyledFormLabel>
                      <StyledFormRow>
                        <StyledFormTiles>


                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-abonament-1miesiac-799zl"
                              checked={this.state.plan === "cowork-abonament-1miesiac-799zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>778zł</StyledFormRadioPrice>
                              1 {this.props.t('offerCoworking.month')}
                              <span>( {this.props.t('reservations.monthly')} )</span>
                            </StyledFormRadioText>
                          </StyledFormTile>
                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-abonament-3-miesiecy-749zl"
                              checked={this.state.plan === "cowork-abonament-3-miesiecy-749zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>749zł</StyledFormRadioPrice>
                              3 {this.props.t('offerCoworking.months1')}
                              <span>( {this.props.t('reservations.monthly')} )</span>
                            </StyledFormRadioText>
                          </StyledFormTile>

                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-abonament-6-miesiecy-platnosc-jednorazowa-730zl"
                              checked={this.state.plan === "cowork-abonament-6-miesiecy-platnosc-jednorazowa-730zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>730zł</StyledFormRadioPrice>
                              6 {this.props.t('offerCoworking.months1')}
                              <span>( {this.props.t('reservations.monthly')} )</span>
                            </StyledFormRadioText>
                          </StyledFormTile>
                          <StyledFormTile>
                            <StyledFormRadio
                              type="radio"
                              name="plan"
                              value="cowork-abonament-12-miesiecy-699zl"
                              checked={this.state.plan === "cowork-abonament-12-miesiecy-699zl"}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>699zł</StyledFormRadioPrice>
                              12 {this.props.t('offerCoworking.months1')}
                              <span>( {this.props.t('reservations.monthly')} )</span>
                            </StyledFormRadioText>
                          </StyledFormTile>

                          {/*<StyledFormTile>*/}
                          {/*  <StyledFormRadio*/}
                          {/*    type="radio"*/}
                          {/*    name="plan"*/}
                          {/*    value="cowork-abonament-12-miesiecy-platnosc-jednorazowa-7999zl"*/}
                          {/*    checked={this.state.plan === "cowork-abonament-12-miesiecy-platnosc-jednorazowa-7999zl"}*/}
                          {/*    onChange={this.handleChange} />*/}
                          {/*  <StyledFormRadioText>*/}
                          {/*    <StyledFormRadioPrice>7999zł</StyledFormRadioPrice>*/}
                          {/*    12 Miesięcy*/}
                          {/*    <span>( jednorazowo )</span>*/}
                          {/*  </StyledFormRadioText>*/}
                          {/*</StyledFormTile>*/}
                        </StyledFormTiles>
                    </StyledFormRow>
                      </>
                    }


                  </>
                }

                {
                  this.state.type === "wirtualne"
                  &&
                  <>
                    <StyledFormLabel>{this.props.t('reservations.choosePackage')}</StyledFormLabel>
                    <StyledFormRow>
                      <StyledFormTiles>
                        <StyledFormTile>
                          <StyledFormRadio
                            type="radio"
                            name="plan"
                            value="biuro-miesiac-140"
                            checked={this.state.plan === "biuro-miesiac-140"}
                            onChange={this.handleChange}/>
                          <StyledFormRadioText>
                            <StyledFormRadioPrice>
                              140 zł
                              <StyledOfferNet>netto</StyledOfferNet>
                            </StyledFormRadioPrice>
														{this.props.t('virtualOffice.package1')}
                            <span>( 140 zł/{this.props.t('offerCoworking.month')} )</span>
                          </StyledFormRadioText>
                        </StyledFormTile>
                        <StyledFormTile>
                          <StyledFormRadio
                            type="radio"
                            name="plan"
                            value="biuro-pol-roku-720"
                            checked={this.state.plan === "biuro-pol-roku-720"}
                            onChange={this.handleChange} />
                          <StyledFormRadioText>
                            <StyledFormRadioPrice>
                              720 zł
                              <StyledOfferNet>netto</StyledOfferNet>
                            </StyledFormRadioPrice>
														{this.props.t('virtualOffice.package2')}
                            <span>( 120 zł/{this.props.t('offerCoworking.month')} )</span>
                          </StyledFormRadioText>
                        </StyledFormTile>
                        <StyledFormTile>
                          <StyledFormRadio
                            type="radio"
                            name="plan"
                            value="biuro-rok-1200"
                            checked={this.state.plan === "biuro-rok-1200"}
                            onChange={this.handleChange} />
                          <StyledFormRadioText>
                            <StyledFormRadioPrice>
                              1200 zł
                              <StyledOfferNet>netto</StyledOfferNet>
                            </StyledFormRadioPrice>
														{this.props.t('virtualOffice.package3')}
                            <span>( 100 zł/{this.props.t('offerCoworking.month')} )</span>
                          </StyledFormRadioText>
                        </StyledFormTile>
                      </StyledFormTiles>
                    </StyledFormRow>
                  </>
                }

                {
                  this.state.type === "sala-konferencyjna"
                  &&
                  <>
                    <StyledFormLabel>{this.props.t('reservations.chooseConference')}</StyledFormLabel>
                    <StyledFormRow>
                      <StyledFormTiles>
                        {/*<StyledFormTile>*/}
                        {/*  <StyledFormRadio*/}
                        {/*    type="radio"*/}
                        {/*    name="salaTyp"*/}
                        {/*    value="sala-dolna"*/}
                        {/*    checked={this.state.salaTyp === "sala-dolna"}*/}
                        {/*    onChange={this.handleChange}/>*/}
                        {/*  <StyledFormRadioText>*/}
                        {/*    Sala dolna*/}
                        {/*    <span>( do 10 osób )</span>*/}
                        {/*  </StyledFormRadioText>*/}
                        {/*</StyledFormTile>*/}

                        {/* <StyledFormTile>
                          <StyledFormRadio
                            type="radio"
                            name="salaTyp"
                            value="sala-akwarium"
                            checked={this.state.salaTyp === "sala-akwarium"}
                            onChange={this.handleChange} />
                          <StyledFormRadioText>
                           Sala akwarium
                            <span>( do 10 osób )</span>
                          </StyledFormRadioText>
                        </StyledFormTile> */}

                        <StyledFormTile>
                          <StyledFormRadio
                            type="radio"
                            name="salaTyp"
                            value="sala-duza"
                            checked={this.state.salaTyp === "sala-duza"}
                            onChange={this.handleChange} />
                          <StyledFormRadioText>
													{this.props.t('reservations.bigTraingRoom')}
                            <span>( 	{this.props.t('reservations.bigTraingRoomCapacity')} )</span>
                          </StyledFormRadioText>
                        </StyledFormTile>
                      </StyledFormTiles>
                    </StyledFormRow>
                {
                  this.state.salaTyp === "sala-dolna"
                  &&
                  <>
                  <StyledFormLabel>{this.props.t('reservations.rentTime')}</StyledFormLabel>
                  <StyledFormRow>
                  <StyledFormTiles grid>
                  {
                    salaDolna.map((item) =>
                      <StyledFormTile key={item.id}>
                        <StyledFormRadio
                          type="radio"
                          name="rentTime"
                          value={"dolna-" + item.plan + "-" + item.price }
                          checked={this.state.rentTime === ("dolna-" + item.plan + "-" + item.price)}
                          onChange={this.handleChange} />
                        <StyledFormRadioText>
                          <StyledFormRadioPrice>{item.price} zł</StyledFormRadioPrice>
                          {item.hours}
                        </StyledFormRadioText>
                      </StyledFormTile>
                    )}
                  </StyledFormTiles>
                  </StyledFormRow>

                  </>
                }
                {
                  this.state.salaTyp === "sala-akwarium"
                  &&
                  <>
                  <StyledFormLabel>Wybierz czas wynajmu:</StyledFormLabel>
                  <StyledFormRow>
                    <StyledFormTiles grid>
                      {
                        salaAkwarium.map((item) =>
                          <StyledFormTile key={item.id}>
                            <StyledFormRadio
                              type="radio"
                              name="rentTime"
                              value={"akwarium-" + item.plan + "-" + item.price }
                              checked={this.state.rentTime === ("akwarium-" + item.plan + "-" + item.price)}
                              onChange={this.handleChange} />
                            <StyledFormRadioText>
                              <StyledFormRadioPrice>{item.price} zł</StyledFormRadioPrice>
                              {item.hours}
                            </StyledFormRadioText>
                          </StyledFormTile>
                        )}
                    </StyledFormTiles>
                  </StyledFormRow>

                  </>
                }
                    {
                      this.state.salaTyp === "sala-duza"
                      &&
                      <>
                        <StyledFormLabel>{this.props.t('reservations.rentTime')}</StyledFormLabel>
                        <StyledFormRow>
                          <StyledFormTiles grid>
                            {
                              salaDuza.map((item) =>
                                <StyledFormTile key={item.id}>
                                  <StyledFormRadio
                                    type="radio"
                                    name="rentTime"
                                    value={"duza-" + item.plan + "-" + item.price }
                                    checked={this.state.rentTime === ("duza-" + item.plan + "-" + item.price)}
                                    onChange={this.handleChange} />
                                  <StyledFormRadioText>
                                    <StyledFormRadioPrice>{item.price} zł</StyledFormRadioPrice>
                                    {this.props.t(item.hours)}
                                  </StyledFormRadioText>
                                </StyledFormTile>
                              )}
                          </StyledFormTiles>
                        </StyledFormRow>

                      </>
                    }
                  </>

                }

                {
                  // Reservation date
                }

                {
                  this.state.type !== "wirtualne"
                  &&
                  <>
                    <StyledFormLabel>{this.props.t('reservations.reservationDetails')}</StyledFormLabel>
                    <StyledDatePickerWrap>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.date}
                        onChange={this.handleDateChange}
                      />
                    </StyledDatePickerWrap>
                  </>
                }
                <StyledFormRow>
                  <div>
                    <textarea
                      name="message"
                      placeholder={this.props.t('reservations.details')}
                      id="message"
                      onChange={this.handleChange}
                      rows="10" />
                  </div>
                </StyledFormRow>

                {
                  // Invoice
                }
                <StyledFormLabel>{this.props.t('reservations.invoice')}</StyledFormLabel>
                <StyledFormRow>
                  <StyledFormTiles>
                    <StyledFormTile>
                      <StyledFormRadio
                        type="radio"
                        name="invoice"
                        value="tak"
                        checked={this.state.invoice === "tak"}
                        onChange={this.handleChange}/>
                      <StyledFormRadioText>
											{this.props.t('reservations.yes')}
                      </StyledFormRadioText>
                    </StyledFormTile>
                    <StyledFormTile>
                      <StyledFormRadio
                        type="radio"
                        name="invoice"
                        value="nie"
                        checked={this.state.invoice === "nie"}
                        onChange={this.handleChange} />
                      <StyledFormRadioText>
											{this.props.t('reservations.no')}
                      </StyledFormRadioText>
                    </StyledFormTile>
                  </StyledFormTiles>
                </StyledFormRow>
                {
                  this.state.invoice === "tak" &&
                  <>
                    <StyledFormLabel>{this.props.t('reservations.companyDetails')}</StyledFormLabel>
                    <StyledFormRow>
                      <div>
                        <input
                          type="text"
                          name="companyName"
                          id="companyName"
                          onChange={this.handleChange}
                          placeholder={this.props.t('reservations.companyName')} />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="companyNIP"
                          id="companyNIP"
                          onChange={this.handleChange}
                          placeholder={this.props.t('reservations.NIP')} />
                      </div>
                    </StyledFormRow>
                    <StyledFormRow>
                      <div>
                        <input
                          type="text"
                          name="companyAddress"
                          id="companyAddress"
                          onChange={this.handleChange}
                          placeholder={this.props.t('reservations.address')} />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="companyREGON"
                          id="companyREGON"
                          onChange={this.handleChange}
                          placeholder="REGON" />
                      </div>
                    </StyledFormRow>
                  </>
                }

                {
                  // Policy checkbox
                }
                <StyledFormRow>
                  <StyledFormCheckbox>
                    <label>
                      <input type="checkbox" required/>
                      <span></span>
                      {this.props.t('reservations.agreement1')}
                      <a href="/polityka-prywatnosci" target="_blank"> {this.props.t('reservations.agreement2')}</a>
                    </label>
                  </StyledFormCheckbox>
                </StyledFormRow>

                <StyledFormRow center>
                  <div>
                    <StyledFormButton type="submit"> {this.props.t('common.send')}</StyledFormButton>
                  </div>
                </StyledFormRow>
              </form>
            </StyledFormWrap>
            {
              this.state.messageBox
              &&
              <StyledMessageBox >
                <div>
                  <span>Udało się!</span>
                  <p>Dziękujemy za wiadomość,
                    <br/>
                    skontaktujemy się z Tobą tak szybko jak to możliwe :)
                  </p>
                  <Button to="/">Powrót</Button>
                </div>
              </StyledMessageBox>
            }
          </GlobalContainer>
        </GlobalSection>
      </>
    )
  }
}

export default withTranslation()(ReservationPage)
